<template>
  <div ref="infiniteLoader">
    <slot />
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import debounce from 'lodash.debounce'

export default {
  name: 'InfiniteLoader',
  emits: ['handler'],
  components: {
    Loader
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted () {
    this.addScroll()

    this.stateHandler = {
      isFirst: true,
      loading: () => {
        this.isLoading = true
      },
      complete: () => {
        this.isLoading = false
      },
      finished: () => {
        this.isLoading = false
        this.removeScroll()
      }
    }
    this.initialLoad()
  },
  unmounted () {
    // We are only concerned with removing scroll on window when unmounted.
    if (!this.hasDefaultSlot) {
      window.removeEventListener('scroll', this.scrollHandler)
    }
  },
  methods: {
    addScroll: function () {
      if (this.hasDefaultSlot) {
        this.$refs.infiniteLoader.addEventListener('scroll', this.scrollContainerHandler)
      } else {
        window.addEventListener('scroll', this.scrollHandler)
      }
    },
    removeScroll: function () {
      if (this.hasDefaultSlot) {
        this.$refs.infiniteLoader.removeEventListener('scroll', this.scrollContainerHandler)
      } else {
        window.removeEventListener('scroll', this.scrollHandler)
      }
    },
    initialLoad: function () {
      // If this is a scroll container, load initial call, otherwise check if the loader is inview.
      if (this.hasDefaultSlot) {
        this.triggerLoad()
      } else {
        this.scrollHandler()
      }
    },
    scrollHandler: debounce(function () {
      // If this is just an element on the page, check when the element is in view.
      const top = this.$refs.infiniteLoader.getBoundingClientRect().top
      if (top < window.innerHeight && !this.isLoading) {
        this.triggerLoad()
      }
    }, 100, { leading: true }),
    scrollContainerHandler: debounce(function () {
      // If this is a scrolling container/div, check when we hit the bottom.
      if (this.$refs.infiniteLoader.scrollHeight - this.$refs.infiniteLoader.scrollTop === this.$refs.infiniteLoader.clientHeight) {
        this.triggerLoad()
      }
    }, 100, { leading: true }),
    triggerLoad: function () {
      this.stateHandler.loading()
      this.$emit('handler', this.stateHandler)
      this.stateHandler.isFirst = false
    }
  }
}
</script>
