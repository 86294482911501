import store from '@/store.js'

class CacheService {
  load (key) {
    this.key = key
    this.cache = JSON.parse(localStorage.getItem(key))
    this.cacheLoadedTimestamp = Date.now()
  }

  isValid () {
    // If the cache exists in local storage, hasn't expired, and the page wasn't just refreshed.
    return this.cache && this.cache.expires > this.cacheLoadedTimestamp && store.timestamp + 5000 < this.cacheLoadedTimestamp
  }

  get () {
    return this.cache
  }

  set (cache) {
    cache.expires = Date.now() + (60 * 10 * 1000)
    localStorage.setItem(this.key, JSON.stringify(cache))
  }

  remove () {
    localStorage.removeItem(this.key)
  }

  removeByKey (key) {
    localStorage.removeItem(key)
  }
}

export default new CacheService()
